import React from "react"

import Button from "./button"

import { css } from '@emotion/react'

import { MdAddCircle } from "react-icons/md"

const AddContent = (props) => {
    const {
        title,
        link,
        object,
    } = props

    return (
        <div css={main} {...props}>
            <h2>{title}</h2>
            <Button 
                link={link}
                text={`Ajouter ${object}`}
                svg={<MdAddCircle />}
                external
            />
        </div>
    )
}

export default AddContent

const main = css`
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    h2 {
        margin: 0 0 10px;
    }

    @media screen and (min-width: 700px) {
        flex-wrap: nowrap;

        h2 {
            margin: 0;
        }
    }
`