import React, { useState } from "react"
import { graphql } from 'gatsby'

import Layout from "../components/layout"
import Seo from "../components/seo"
import ProjectCard from "../components/projectCard"
import Container from "../components/container"
import ProjectsFilter from "../components/projectsFilter"
// import ThreeColumns from "../components/layouts/threeColumns"
import FourColumns from "../components/layouts/fourColumns"
import AddContent from "../components/addContent"

// import { css } from '@emotion/react'

const ProjectsPage = (props) => {
  const projects = props.data.projects.edges.map( edge => edge.node )
  // console.log(projects)

  // const [projects, setProjects] = useState(initialProjects)
  const [filters, setFilters] = useState(new Set())

  let filteredProjects
  if ( filters.size === 0 ) {
    filteredProjects = projects
    // console.log("NO FILTERS")
  } else {
    filteredProjects = projects.filter( project => {
      // console.log(project.data.Sectors, Array.isArray(project.data.Sectors))
      let matches = Array.from(filters).filter( filter => project.data.Tags && project.data.Tags.map( tag => tag.data.Name ).includes(filter))
      if ( matches.length === filters.size ) {
        return true
      } else {
        return false
      }
    })
  }

  return (
    <Layout>
      <Seo title="Projets" />

      <ProjectsFilter 
        filters={filters}
        setFilters={setFilters} 
      />

      <Container>
        <div style={{margin: "50px 0"}}>
          <AddContent 
            title={`${filteredProjects.length} projet${filteredProjects.length > 1 ? 's' : ''} inspirant${filteredProjects.length > 1 ? 's' : ''} en Suisse`}
            link={"https://airtable.com/shrHIOGBJnsvM9bgF"}
            object="un projet"
          />
          <FourColumns>
            {filteredProjects.filter( project => project.data.Logo.localFiles[0].childImageSharp ).map( project => {
              // console.log(project.data.Name, project.data.Cover)
              return (
                <ProjectCard 
                  key={project.id}
                  slug={project.data.Slug}
                  name={project.data.Name}
                  pitch={project.data.Pitch}
                  cover={project.data.Cover && project.data.Cover.localFiles[0].childImageSharp.gatsbyImageData}
                  logo={project.data.Logo && project.data.Logo.localFiles[0].childImageSharp.gatsbyImageData}
                  sectors={project.data.Tags.map( tag => tag.data.Name )}
                  labels={project.data.Labels}
                  devGoals={project.data.SDGs && project.data.SDGs.map( obj => ({
                    id: obj.id,
                    nbr: obj.data.Number,
                  }))}
                  mediaCount={project.data.Podcasts && project.data.Podcasts.length}
                  peopleCount={project.data.People && project.data.People.length}
                />
              )
            })}
          </FourColumns>
          {filteredProjects.length === 0 && <div>Oh non... pas de projets pour le moment sur ce critère de recherche. Essayez autre chose ou soumettez un projet !</div>}
        </div>
      </Container>
    </Layout>
  )
}

export default ProjectsPage


export const projectsQuery = graphql`
  query ProjectsQuery {
    projects: allAirtable(filter: {table: {eq: "Projects"}, id: {ne: "null"}, data: {Countries: {eq: "Suisse"}, Status: {eq: "Published"}}}) {
      edges {
        node {
          ...ProjectCardFragment
        }
      }
    }
  }
`
