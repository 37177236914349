import React from "react"
import { useStaticQuery, graphql } from 'gatsby'

import Container from "../components/container"

import { css } from '@emotion/react'

import { MdRemoveCircle, MdRefresh } from "react-icons/md"

// Il faut de tout pour faire un (nouveau) monde

const ProjectsFilter = (props) => {
    const data = useStaticQuery(
        graphql`
            query {
                categories: allAirtable(filter: {table: {eq: "Projects"}, data: {Status: {eq: "Published"}}}) {
                    group(field: data___Tags___data___Name) {
                        fieldValue
                        totalCount
                    }
                }
            }
        `
    )
    const categories = data.categories.group
    const { filters, setFilters } = props
    // console.log(categories)

    const handleClick = (category) => {
        setFilters(prevFilters => {
            let newFilters = new Set(prevFilters)
            if ( newFilters.has(category) ) {
                newFilters.delete(category)
            } else {
                newFilters.add(category)
            }
            return newFilters
        })
    }

    // Add choice to order tags by number or alphabet
    // .sort( (cat1, cat2) => cat2.totalCount - cat1.totalCount )

    return (
        <div css={main}>
            <Container>
                {categories.map( category => (
                    <button
                        key={category.fieldValue}
                        onClick={() => handleClick(category.fieldValue)}
                        className={filters.has(category.fieldValue) && "active-filter"}
                    >
                        {filters.has(category.fieldValue) && <MdRemoveCircle />}{category.fieldValue}<span>{category.totalCount}</span>
                    </button>
                ))}
                {filters.size > 0 && <button onClick={(e) => setFilters(new Set())}>
                    <MdRefresh style={{color: "black"}} />Reset
                </button>}
            </Container>
        </div>
    )
}

export default ProjectsFilter

const main = css`
    padding: 50px 0;
    background-color: #fafafb;

    > div {
        display: flex;
        flex-wrap: wrap;
    
        button {
            padding: 10px 20px;
            border-radius: 50px;
            border: 1px solid #EFEFEF;
            background-color: white;
            font-size: .8rem;
            margin: 0 10px 10px 0;
            cursor: pointer;
            color: #6f6f6f;
            transition: background-color .1s ease-in-out;
    
            &:focus {
                outline: none;
            }

            svg {
                margin-bottom: -4px;
                font-size: 1rem;
                margin-right: 10px;
            }
    
            &.active-filter, &:hover {
                color: black;
                background-color: #00FFAA;
            
                span {
                    color: black;
                    background-color: white;
                }
            }

            span {
                font-size: .7rem;
                color: #6f6f6f;
                display: inline-block;
                margin-left: 10px;
                background-color: rgba(0, 0, 0, .05);
                border-radius: 20px;
                padding: 0 10px;
            }
        }
    }
`